<template>
  <div class="weap">

    <div class="left">

      <span>Copyright ©2023</span>

      <span>Zhongke Radio (Guangdong) Co., LTD</span>

      <span>all right reserved</span>

    </div>

    <div class="right">

      <span></span>
      <span><a href="#"><!-- xxx --></a></span>

    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">

</style>
