<template>
  <div class="el-header">
    <div class="qm-top" ref="qmTop">
      <div class="box">
        <div class="weap">
          <div class="logo"><router-link to="javascript:;" title="logo"><img src="../../../public/assets/img/logo.png"
                alt=""></router-link>
          </div>
          <div class="qm-nav">
            <ul class="navul">
              <!-- on属性是当前在的页面 -->
              <!-- 一级 -->
              <li :class="[item.children ? 'exist nl' : 'nl', routepath == item.to ? 'on' : '']"
                v-for="(item, indexul) in navlist" @click.stop="cs($event)" :key="item.title">
                <router-link :to="item.to" class="alink">{{ item.title }} </router-link>
                <!-- 二级 -->
                <ul v-if="item.children" class="showul"
                  :style="[{ overflow: 'hidden' }, { display: existblock == indexul ? 'block' : '' }]">
                  <li v-for="child in item.children" :key="child.title" @click.stop="showQmTop">
                    <router-link :to="child.to">{{ child.title }}</router-link>
                  </li>
                </ul>
                <span v-if="item.children" @click="toggleChildBlock(indexul, $event)">+</span>
              </li>
            </ul>
            <div class="mclose" @click.stop="showQmTop"></div>
          </div>
          <div class="topright">
            <div class="a search-a" @click="changeshow"></div>
            <div :class="show == true ? 'a lang-a on' : 'a lang-a'" @mouseenter="show = true" @mouseleave="show = false">
              <span class="s">EN</span>
              <div class="sub">
                <ul>
                  <li><a href="https://geli.wanyuanxiang.com/" target="_blank">CN</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="m-nav" @click="showQmTop"><a></a></div>
        </div>
      </div>
    </div>
    <Index01 v-show="$store.state.issearch"></Index01>
  </div>
</template>

<script>
import Index01 from '../../views/home/index01.vue'

export default {
  components: {
    Index01,
  },
  data() {
    return {
      is_showsearch: false,
      show: false,
      existblock: '',
      routepath: '',
      navlist: [
        {
          title: 'INDEX',
          to: '/home'
        },
        {
          title: 'ABOUT US',
          to: '/about',
          children: [
            {
              title: 'Memorabilia',
              to: '/memorabilia'
            },
            {
              title: 'About JSCJ',
              to: '/about'
            }/* ,
            {
              title: '加入中科',
              to: '/careers'
            } */
          ]
        },
        {
          title: 'NEWS',
          to: '/news'
        },
        {
          title: 'PRODUCT',
          to: '/erjiguan/1',
          children: [
            {
              title: 'Diodes',
              to: '/erjiguan/1'
            },
            {
              title: 'Bipolar Transistors',
              to: '/erjiguan/2'
            },
            {
              title: 'MOSFETs',
              to: '/erjiguan/3'
            },
            {
              title: 'ICs',
              to: '/erjiguan/4'
            },
            {
              title: 'IGBTs',
              to: '/erjiguan/5'
            },
            {
              title: 'Third Generation Semiconductors',
              to: '/erjiguan/6'
            },
            {
              title: 'Automotive Electronics',
              to: '/erjiguan/7'
            },
            {
              title: 'Others',
              to: '/erjiguan/8'
            }
          ]
        },
        {
          title: 'APPLICATION',
          to: '/field'
        },
        {
          title: 'QMS',
          to: '/zhiliangfangzhen/zhiliangfangzhen',
          children: [
            {
              title: 'Policy',
              to: '/zhiliangfangzhen/zhiliangfangzhen'
            },
            {
              title: 'Cretificate',
              to: '/zhiliangfangzhen/tixizhengshu'
            },
            {
              title: 'Environmental Sustainability',
              to: '/zhiliangfangzhen/huanjingwuzhijiancebaogao'
            }
          ]
        },
        {
          title: 'CONTACT',
          to: '/contact'
        }
      ],
    }
  },
  methods: {
    changeshow() {
      // this.is_showsearch = !this.is_showsearch
      if (this.$route.path != '/search') {
        this.$store.commit('SET_SEARCH', !this.$store.state.issearch)
      }
    },
    showQmTop() {
      if (!this.$refs['qmTop'].classList.contains('block')) {
        this.$refs['qmTop'].classList.add('block')
      } else {
        this.$refs['qmTop'].classList.remove('block')
      }
    },
    toggleChildBlock(i, e) {
      if (this.existblock != i) {
        this.existblock = i
        e.target.innerHTML = '-'
      } else {
        this.existblock = ''
        e.target.innerHTML = '+'
      }
    },
    cs(e) {
      if (e.target.tagName == 'A') {
        this.showQmTop()
      }
    }
  },
  watch: {
    $route(val) {
      this.routepath = ''
      this.navlist.forEach(element => {
        if (!element.children) {
          if (element.to == val.path) {
            this.routepath = val.path
          }
        } else {
          element.children.forEach(child => {
            if (child.to == val.path) {
              this.routepath = element.to
            }
          });
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped></style>
