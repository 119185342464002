<template>
    <div class="index01 top-search-box hidden">
        <div class="weap">
            <div class="bd">
                <div class="searchbox top-search-input">
                    <form action="" method="get">
                        <input type="text"  :value="$store.getters.searchtxt" name="q" @input="set_text" placeholder="Please enter product keywords" class="in-put"
                            autocomplete="off">
                            <input type="button" class="in-btn" name="" value="" @click="serach" />
                        </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    data() {
        return {
            text: ''
        }
    },
    methods: {
        serach() {
            if(this.$route.path!='/search'){
                this.$router.push({ path: '/search',query:{text:this.text}});
                this.$store.commit('settxt',this.text)
                this.$store.commit('SET_SEARCH',false)
            }else{
                this.$store.commit('SET_SEARCH',!this.$store.state.issearch)
            }
        },
        set_text(e){
            this.text=e.target.value
            this.$store.commit('settxt',e.target.value)
        }
    }
}
</script>
<style lang="scss" scoped>
.index01 {
    background-color: #F8F8F8;
    color: #fff;
    padding-top: 50px;
    padding-bottom: 35px;
}

.index01 .bd {
    width: 580px;
    max-width: 100%;
    margin: 0 auto
}

.index01 .bd .t {
    font-size: 18px;
    margin-bottom: 15px
}
.hidden{
    display: block;
}

</style>